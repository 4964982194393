import React, { useState } from 'react';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdb-react-ui-kit';

const ProductCard = ({ index, title, imageSrc, description }) => {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(true);
  };

  const handleLeave = () => {
    setHovered(false);
  };

   const buttonStyle = {
    opacity: hovered ? 1 : 0,
    pointerEvents: hovered ? 'auto' : 'none',
  };

  return (
    <MDBCol key={index}>
      <MDBCard
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
        style={{
          transition: 'transform 0.3s',
          transform: hovered ? 'scale(0.9)' : 'scale(1)',
        }}
      >
        <MDBCardImage src={imageSrc} alt={`Card ${index}`} position='top'/>
        <MDBCardBody>
          <MDBCardTitle>{title}</MDBCardTitle>
          <MDBCardText>{description}</MDBCardText>
          <MDBBtn href='#' style={buttonStyle} disabled={!hovered}>
            View Details
          </MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  );
};

export default function Products() {
  const productsData = [
    { index: 1, title: 'Indoor LC2P', imageSrc:require('../Images/Indoor LC2P.jpg'), description: 'This is the special type of LED Screen and Its have 2MM Pixel pitch more over Module Resolution(W*H) is 160*80.This Module have 320*160*16.6 mm and it will store the -20’C to 50’C' },
    { index: 2, title: 'Indoor LC1.25P', imageSrc:require('../Images/Indoor LC1.25P.jpg'), description: ' This is the special type of LED Screen(SMD1010) and Its have 1.25MM Pixel pitch more over Module Resolution(W*H) is 256*128.This Module have 320*160*16.6 mm and it will store the -20’C to 50’C' },
    { index: 2, title: 'Outdoor LC6.667PO ', imageSrc:require('../Images/Outdoor LC6.667PO.jpg'), description: 'This is the special type of LED Screen (SMD2727) and Its have 6.677MM Pixel pitch more over Module Resolution(W*H) is 48*24.This Module have 320*160*18 mm and it will store the -20’C to 55’C' },
    { index: 2, title: 'Outdoor LC5PO ', imageSrc:require('../Images/Outdoor LC5PO.jpg'), description: 'This is the special type of LED Screen(SMD121) and Its have 5MM Pixel pitch more over Module Resolution(W*H) is 64*32.This Module have 320*160*18 mm and it will store the -20’C to 50’C                                                ' }

    // Add more product data as needed
  ];

  return (
    <MDBRow className='row-cols-1 row-cols-md-2 g-4'>
      {productsData.map((product) => (
        <ProductCard key={product.index} {...product} />
      ))}
    </MDBRow>
  );
}

