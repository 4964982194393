import React from 'react'
import pb from "../Images/pbg.jpg"
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBContainer
} from 'mdb-react-ui-kit';
import Footer from '../IFoot/Footer';

export default function Sepproducts() {
  return (
    <div>
         
         <div
          id="intro"
          className="bg-image"
          style={{
            backgroundImage:
            `url(${pb})`,
            height: '40vh',
          }}
        >
          <div className="mask text-white" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <div className="container d-flex align-items-center justify-content-center text-center h-100">
            <div>
                <h1 className="mb-5" style={{
                        backgroundColor: 'white',
                        fontSize: '2em', 
                        padding: '0.5em 1em', 
                        color: 'black', 
                                    }}> PRODUCTS </h1>
              </div>
            </div>

          </div>
        </div>

        <MDBContainer  breakpoint="sm" className="my-5" >

          <h1 style={{color:'black'}}>Our Products List</h1>
        
        <MDBContainer className="my-5" >
        <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={require('../Images/IndoorP1.538.png')}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Indoor P1.538</MDBCardTitle>
           
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
           src={require('../Images/IndoorP2.png')}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Indoor P2</MDBCardTitle>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={require('../Images/IndoorP4.png')}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Indoor P4</MDBCardTitle>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={require('../Images/OutdoorP10.png')}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Outdoor P10</MDBCardTitle>
            {/* <MDBCardText>
              This is a longer card with supporting text below as a natural lead-in to additional content.
              This content is a little bit longer.
            </MDBCardText> */}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
              src={require('../Images/OutdoorP4.png')}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Outdoor P4</MDBCardTitle>
            {/* <MDBCardText>This is a short card.</MDBCardText> */}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={require('../Images/OutdoorP5.926.png')}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle> Outdoor P5.926</MDBCardTitle>
            {/* <MDBCardText>
              This is a longer card with supporting text below as a natural lead-in to additional content.
            </MDBCardText> */}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={require('../Images/P1.25.png')}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle> Indoor P1.25</MDBCardTitle>
            {/* <MDBCardText>
              This is a longer card with supporting text below as a natural lead-in to additional content.
              This content is a little bit longer.
            </MDBCardText> */}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </MDBContainer>

    < MDBContainer className="my-5" >
      <Footer/>
    </MDBContainer>
    </MDBContainer>
    </div>
  )
}
