import React from 'react'
import ebanner from "../Images/e-waste-banner.jpg"
import {
    MDBCard,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBContainer
  } from 'mdb-react-ui-kit';
  import ew1 from "../Images/eww.jpg"
  import ew2 from "../Images/ew2.jpg"
  import ewm from "../Images/ewtab.png"
import Footer from '../IFoot/Footer';


export default function Ewaste() {
  return (
    <div>

         <div
          id="intro"
          className="bg-image"
          style={{
            backgroundImage:
            `url(${ebanner})`,
            height: '40vh',
          }}
        >
          <div className="mask text-white" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <div className="container d-flex align-items-center justify-content-center text-center h-100">
            <div>
                <h1 className="mb-5" style={{
                        backgroundColor: 'white',
                        fontSize: '2em', 
                        padding: '0.5em 1em', 
                        color: 'black', 
                                    }}> E-WASTE </h1>
              </div>
            </div>

          </div>
        </div>
          
        <MDBContainer  breakpoint="sm" className="my-5" >
        <b style={{color:'black'}}>At INNOVATIVE LED TECHNOLOGY INDIA  we understand that our responsibility doesn’t end at
                                selling you our products. INNOVATIVE LED TECHNOLOGY INDIA  has been working in the area of
                                safe disposal of electronic waste. This thought is supported by our global policy, as
                                well the legislation passed by the Ministry of Environment and Forests (MoEF) (called
                                E-waste (Management &amp; Handling) Rules, 2016), which came into effect from 1st Oct 2016.

                            </b>

                           <p className="my-5" >INNOVATIVE LED TECHNOLOGY INDIA . will seek shared responsibility and cooperation from customers in reducing the environmental impact of their products.</p>

                            <MDBContainer  breakpoint="sm" className="my-5" >
            
                            {/* <p>As part of the e-waste recycling initiative, INNOVATIVE LED TECHNOLOGY INDIA . has authorized an
                            E-waste Recycling company, named E-Waste Recylers India, to collect, dismantle and recycle
                            E-waste – collected from all over India. E-Waste Recylers India, with its patented
                            technologies, with over 200+ patents filed, is one of the top ten global players. E-Waste
                            Recylers India vision is to look at E-waste not just as waste, but as an important resource
                            that can be made useful – instead of shunning it as a social and environmental burden with
                            their fully automated facility for e-waste recycling.
                        </p> */}
                        </MDBContainer>


                        <MDBContainer  breakpoint="sm" className="my-5" >

<MDBRow className='row-cols-1 row-cols-md-2 g-4'>
<MDBCol>
<MDBCard>
  <MDBCardImage
    src={ew1}
    alt='...'
    position='top'
  />
</MDBCard>
</MDBCol>
<MDBCol>
<MDBCard>
  <MDBCardImage
    src={ew2}
    alt='...'
    position='top'
  />
</MDBCard>
</MDBCol>
</MDBRow>
</MDBContainer>


<h1 className="my-5" style={{color:'black'}}>The Dangers of E-waste</h1>
<p className="my-2">According to the World Health Organization (WHO), health risks may result from direct contact with toxic materials that leach from e-waste. These include minerals such as lead, cadmium, chromium, brominated flame retardants, or polychlorinated biphenyls (PCBs). Danger can come from inhalation of the toxic fumes, as well as from the accumulation of chemicals in soil, water, and food.

This puts not just people in danger but land and sea animals as well. In developing countries, the risks are exceptionally high because some developed countries send their e-waste there. Studies have shown this global e-waste has detrimental effects on the people that work with the e-waste but also the people that live around it.

Because of this, a proper recycling process needs to be put in place to protect us and future generations.</p>

{/* <h1 className="my-5" style={{color:'black'}}>Collection Points</h1> */}

<MDBContainer className="my-4">
<MDBCol>
<MDBCard>
  <MDBCardImage
    // src={require('../Images/ewaste.jpg')}
    // alt='...'
    // position='top'
  />
</MDBCard>
</MDBCol>
</MDBContainer>

<p className="my-5">E-waste or electronic waste is created when an electronic product is discarded after the end of its useful life. The rapid expansion of technology and the consumption driven society results in the creation of a very large amount of e-waste.</p>

<MDBContainer className="my-5" >
<Footer/>
</MDBContainer>
 </MDBContainer>

      
    </div>
  )
}
