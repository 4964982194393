import React from 'react';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

export default function AboutUs() {
  return (
    <MDBRow className='row-cols-1 row-cols-md-2 g-4'>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={require('../Images/logo.jpg')}
            alt='...'
            position='top'
            width={300}
            height={400}
          />
        </MDBCard>
      </MDBCol>

      <MDBCol>
        
           
          <MDBCardBody>
            {/* <MDBCardTitle>Card title</MDBCardTitle> */}
            <MDBCardText>
<h2>A World Leading Digital Solution Provider</h2>
Innovative LED is one of the leading LED video wall solutions providers. Innovative has spread its wings among Government Sector, Healthcare, Retail, Corporate, Advertisement, Banking, Cineplex, Theaters, Auditoriums, Sports – Stadiums, Staging, Digital Signage, Hotels, Bars, Car Showrooms, Share Trading & Stock Exchanges, Religious Places – Temples, Shopping Malls, Real Estate Developers, Security & Surveillance, TV Channels, DTH Uplink Center, Broadcast Teleports, Mobile Hoardings, Rental and last but not the least Event Management Companies
<h3>Waterproof LED Walls</h3>
Innovative LED VIDEO WALLS are waterproof and weatherproof. Our Screens offer consistent picture quality in both day & night time, which can be connected to various input formats like VCD – DVD Player, Blue Ray Player, DTH, Cable TV – HDTV, Internet, Computer VGA, DVI & HDMI Output and Various Portable Media Player.
            </MDBCardText>
          </MDBCardBody>
        
      </MDBCol>


     
    </MDBRow>
  );
}