import React from 'react';
import { MDBBtn, MDBIcon,MDBContainer,MDBRow ,MDBCol,MDBCard} from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <>
    <MDBContainer breakpoint="sm"  style={{ borderBottom: '1px solid grey', padding:'20px',borderTop: '1px solid grey', }}>

    <MDBRow className='row-cols-1 row-cols-md-2 g-4'>
    <MDBCol>
       
        <MDBIcon fas icon="map-marker-alt" />
        <span style={{ marginLeft: '8px' }}><b>Address</b></span>
        {/* <a href="" target="_blank"> */}
  <p>
  Innovative LED Technology India
Plot No. M-5/A, 2nd and 3rd Floor,
Block-M, Badli Industrial Area, Phase -1,
Delhi -110042
  </p>
{/* </a> */}
              
        </MDBCol>

        <MDBCol>
        <div>
        <MDBIcon fas icon="phone" />
        <span style={{ marginLeft: '8px' }}><b>Phone</b></span>
        </div>
         <p>+91 9810103697</p>
          
        </MDBCol>

        <MDBCol>
        
        <div>
  <MDBIcon fas icon="envelope" />
  <span style={{ marginLeft: '8px' }}><b>Email</b></span>
</div>
      <p>innovativeilti@gmail.com</p>
        </MDBCol>

        <MDBCol>
        <MDBBtn className='mx-2' tag='a'  outline floating size='lg'>
        <MDBIcon fab icon='facebook-f' />
      </MDBBtn>
      <MDBBtn className='mx-2' tag='a'  outline floating size='lg'>
        <MDBIcon fab icon='twitter' />
      </MDBBtn>
      <MDBBtn className='mx-2' tag='a'  outline floating size='lg'>
        <MDBIcon  fab icon='youtube' />
      </MDBBtn>
      <MDBBtn className='mx-2' tag='a'  outline floating size='lg'>
        <MDBIcon fab icon='linkedin-in' />
      </MDBBtn>
      </MDBCol>
    </MDBRow>
    </MDBContainer>
      
      
    
    <br />
    <p>© Copyrights. All rights reserved.</p>
  
    </>
  );
}