import React, { useState, useEffect } from 'react';
import logo from "../Images/logo.jpg";
import './Fpage.css'

export default function Fpage() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 50) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  return (
    <div>
       <header>
      
      <nav
        className={`navbar navbar-expand-lg fixed-top ${scrolled ? 'navbar-scrolled' : 'navbar-scroll'}`}
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler ps-0"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarExample01"
            aria-controls="navbarExample01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="d-flex justify-content-start align-items-center">
              <i className="fas fa-bars"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarExample01">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item active">
                <a className="nav-link" aria-current="page" href="/" target="_self">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/pro"
                  rel="nofollow"
                  target="_self"
                >
                  Products
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/ew"
                  rel="nofollow"
                  target="_self"
                >
                   E-Waste
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/ab-us"
                  rel="nofollow"
                  target="_self"
                >
                  About Us
                </a>
              </li>

            </ul>

            <ul className="navbar-nav flex-row">
              <li className="nav-item" style={{marginRight:"20px"}}>
              <img src={logo} alt="Your Image" height="40px" width="70px" />
              </li>
            </ul>
          </div>
        </div>
      </nav>

      
    </header>
    </div>
  )
}
