import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Components/Login';
import Nav from './Components/Nav';
import Fpage from './Components/NFEP/Fpage';
import Sepproducts from './Components/Plist/Sepproducts';
import Ewaste from './Components/E-Waste/Ewaste';
import AboutUsMain from './Components/About/AboutUsMain';

function App() {
  return (
    <BrowserRouter>
    <Fpage/>
      <Routes>
      <Route path="/" element={<Nav/>} />
      <Route path="log" element={<Login/>} />
      <Route path="pro" element={<Sepproducts/>} />
      <Route path="ew" element={<Ewaste/>} />
      <Route path="ab-us" element={<AboutUsMain/>} />
      </Routes>
    </BrowserRouter>
    
    
  );
}

export default App;
