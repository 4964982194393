import './Nav.css'
import Carousel from './Slide/Carousel';
import React, { useRef, useEffect } from 'react';
import Products from "./Plist/Products";
import { MDBContainer } from 'mdb-react-ui-kit';
import ContactUs from "./Approach/ContactUs";
import Footer from "./IFoot/Footer";
import AboutUs from "./About/AboutUs";
import banner from "./Images/led-banner.jpg"
import Fpage from "./NFEP/Fpage";
import Typed from 'typed.js';


export default function Nav() {
  const typedTextRef = useRef(null);

  useEffect(() => {
    const options = {
      strings: [
        '<span class="segment-1">INNOVATIVE</span> <span class="segment-2">LED</span> <span class="segment-3">TECHNOLOGY</span> <span class="segment-4">INDIA</span>',
        '<span class="segment-5">I</span> <span class="segment-6">L</span> <span class="segment-7">T</span> <span class="segment-7">I</span>',
      ],
      typeSpeed: 50, 
      backSpeed: 30, 
      startDelay: 500,
      showCursor: false,
      loop: true, 
    };

    const typed = new Typed(typedTextRef.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <>
      <header>
       <Fpage/>
        <div
              id="intro"
              className="bg-image"
              style={{
                backgroundImage: `url(${banner})`,
                height: '95vh',
              }}
            >
              <div className="mask text-white" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                <div className="container d-flex align-items-center justify-content-center text-center h-100">
                  <div>
                  <h1 className="mb-5" ref={typedTextRef}>
          <span className="segment-1">INNOVATIVE</span> <span className="segment-2">LED</span> <span className="segment-3">TECHNOLOGY</span> <span className="segment-4">INDIA</span>
        </h1>
          </div>
        </div>
      </div>
    </div>

      </header>
      <br></br>
      <MDBContainer  breakpoint="sm">
      <h1 style={{ fontFamily: 'revert', color: 'black'}}>
      Screens</h1>
      <br></br>
      <Carousel/>
      </MDBContainer>
      <br></br>
      <MDBContainer breakpoint="md">
       <h1 style={{fontFamily:'revert',color:'black'}}>Products</h1>
       <br></br>
      <Products/>
      </MDBContainer>
      <br></br>
      <MDBContainer breakpoint="md">
       <h1 style={{fontFamily:'revert',color:'black'}}>About Us</h1>
       <br></br>
       <AboutUs/>
      </MDBContainer>
      <br></br>
      <MDBContainer breakpoint="md">
       <h1 style={{fontFamily:'revert',color:'black'}}>Contact Us</h1>
       <br></br>
       <ContactUs/>
      </MDBContainer>
      <center>

      <Footer/>
      </center>
    </>
    
  )
}


