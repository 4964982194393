import React from 'react'
import {
    MDBCard,
    MDBCardImage,
    MDBCol,
    MDBContainer
  } from 'mdb-react-ui-kit';
  import ab from "../Images/logo.jpg"
import aban from "../Images/logo.jpg"
import Footer from '../IFoot/Footer';
export default function AboutUsMain() {
  return (
    <>
       <div
          id="intro"
          className="bg-image"
          style={{
            backgroundImage:
            `url(${aban})`,
            height: '40vh',
          }}
        >
          <div className="mask text-white" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <div className="container d-flex align-items-center justify-content-center text-center h-100">
            <div>
                <h1 className="mb-5" style={{
                        backgroundColor: 'white',
                        fontSize: '2em', 
                        padding: '0.5em 1em', 
                        color: 'black', 
                         }}> About Us </h1>
              </div>
            </div>

          </div>
        </div> 
        <MDBContainer  breakpoint="sm" className="my-5" >
            <h1 style={{color:'black'}}>About - ILTI </h1>
        <MDBContainer className="my-4" >
        <MDBCol >
        <MDBCard >
        <MDBCardImage
            src={ab}
            alt='...'
            position='top'
            style={{ width: '50vw', height: '50vh' }}
           
        />
        </MDBCard>
        </MDBCol>
        </MDBContainer>
        <MDBContainer  breakpoint="sm" className="my-5" >
         <h2 className="my-5" style={{color:'black'}}>A World Leading Digital Solution Provider</h2>
    Innovative LED is one of the leading LED video wall solutions providers. Newtech has spread its wings among Government Sector, Healthcare, Retail, Corporate, Advertisement, Banking, Cineplex, Theaters, Auditoriums, Sports – Stadiums, Staging, Digital Signage, Hotels, Bars, Car Showrooms, Share Trading & Stock Exchanges, Religious Places – Temples, Shopping Malls, Real Estate Developers, Security & Surveillance, TV Channels, DTH Uplink Center, Broadcast Teleports, Mobile Hoardings, Rental and last but not the least Event Management Companies
    <h3 className="my-5" style={{color:'black'}}>Waterproof LED Walls</h3>
    Innovative LED VIDEO WALLS are waterproof and weatherproof. Our Screens offer consistent picture quality in both day & night time, which can be connected to various input formats like VCD – DVD Player, Blue Ray Player, DTH, Cable TV – HDTV, Internet, Computer VGA, DVI & HDMI Output and Various Portable Media Player.
    </MDBContainer>
         <Footer/>
        </MDBContainer>
    </>
  )
}
