import React from 'react';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';

export default function Carousel() {
  return (
    <MDBCarousel showControls showIndicators>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src={require("../Images/Picture1.jpg")}
        alt='...'
      >
  
      </MDBCarouselItem>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={2}
        src={require("../Images/Picture2.jpg")}
        alt='...'
      >

      </MDBCarouselItem>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={3}
        src={require("../Images/Picture3.jpg")}
        alt='...'
      >
 
      </MDBCarouselItem>

    </MDBCarousel>
  );
}